<template>
  <section id="dashboard-analytics">
    <b-col class="content-header-left mb-1" cols="12" md="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">CRM</h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb style="font-size: 0.9rem">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="14"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item active v-if="admin" > MASTER </b-breadcrumb-item>
              <b-breadcrumb-item v-else to="/master"> MASTER </b-breadcrumb-item>
              <b-breadcrumb-item active> CRM </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-row class="match-height">
      <b-col md="3" cols="6" xl="2" v-for="data in modules" :key="data.id">
        <statistic-card-vertical
          :icon="data.icon"
          :statistic="data.title"
          color="danger"
          :route="data.route"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    // kFormatter,
    StatisticCardVertical,

    // InvoiceList,
  },
  data() {
    return {
      data: [
        {
          icon: "CompassIcon",
          title: "Lead Source",
          route: "/master/crm/leadsource",
        },
        {
          icon: "BookOpenIcon",
          title: "Lead Category",
          route: "/master/crm/leadcategory",
        },
        {
          icon: "CommandIcon",
          title: "Lead Stage",
          route: "/master/crm/leadstage",
        },
        {
          icon: "MinimizeIcon",
          title: "Campaign",
          route: "/master/crm/campaign",
        },
        {
          icon: "ApertureIcon",
          title: "Reason",
          route: "/master/crm/reason",
        },
        {
          icon: "UsersIcon",
          title: "Channel Partner",
          route: "/master/crm/channelpartner",
        },
        {
          icon: "FramerIcon",
          title: "Franchise",
          route: "/master/crm/franchise",
        },
        {
          icon: "PackageIcon",
          title: "Project",
          route: "/master/crm/projectmaster",
        },
        {
          icon: "MapIcon",
          title: "Plot",
          route: "/master/crm/plotmaster",
        },
        {
          icon: "SlackIcon",
          title: "Land",
          route: "/master/crm/land",
        },
        {
          icon: "GitCommitIcon",
          title: "Commission",
          route: "/master/crm/commission",
        },
        {
          icon: "MapPinIcon",
          title: "Project Mapping",
          route: "/master/crm/projectmapping",
        },
        {
          icon: "MapIcon",
          title: "Area",
          route: "/master/crm/area",
        },
        {
          icon: "HardDriveIcon",
          title: "Vehicle",
          route: "/master/crm/vehicle",
        },
        {
          icon: "UserIcon",
          title: "Driver",
          route: "/master/crm/driver",
        },
        {
          icon: "CompassIcon",
          title: "Zone",
          route: "/master/crm/zone",
        },
      ],
      userRole:'',
      sidebarData:[],
      modules:[],
      userData: JSON.parse(localStorage.getItem("userData")),
      admin:false,

    };
  },
  mounted() {
    this.userRole = this.userData.role;
    this.sidebarData = JSON.parse(localStorage.getItem("sidebarData"));
    this.admin= (this.userRole == 'admin')

    // if(this.userRole !== 'admin'){
      this.setRoute();
    // }
  },
  methods: {
    // kFormatter
    setRoute () {
      this.sidebarData.map((item) => {
        if (item.title == 'Masters') {
          item.children.map((child)=>{
            if(child.title == 'CRM'){
              this.modules = child.children
            }
          })
        }
      })
      this.modules.map((item) => {
        this.data.map((inner) => {
          if (inner.title == item.title) {
            item.icon = inner.icon
            item.route = inner.route
          }
        })
      })
    }
  }
};
</script>
